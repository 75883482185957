<template>
  <v-container
    float
    fluid
  >
    <v-card>
      <iframe
        :src="`../../pdfs/kyd-privacy-policy.pdf`"
        type="application/pdf"
        width="100%"
        height="630px;"
      />
    </v-card>
  </v-container>
  <!-- <div>
    <v-card>
      <v-card-title>
        PRIVACY NOTICE
        Last updated May 31, 2021
      </v-card-title>
      <v-card-subtitle>
        Thank you for choosing to be part of our community at {{ company.name }}
        ("Company", "we", "us", "our").
        We are committed to protecting your personal information and your right to privacy.
        If you have any questions or concerns about this privacy notice,
        or our practices with regards to your personal information,
        please contact us at {{ company.email }}.
        When you use our mobile application, as the case may be (the "App") and more generally,
        use any of our services (the "Services", which include the App),
        we appreciate that you are trusting us with your personal information.
        We take your privacy very seriously.
        In this privacy notice,
        we seek to explain to you in the clearest way possible what information we collect,
        how we use it and what rights you have in relation to it.
        We hope you take some time to read through it carefully, as it is important.
        If there are any terms in this privacy notice that you do not agree with,
        please discontinue use of our Services immediately.),
        as well as, any related services, sales, marketing or events.
      </v-card-subtitle>
      <v-card-text>
        <div
          class="pt-2"
          style="font-weight: bold;"
        >
          1. WHAT INFORMATION DO WE COLLECT?
        </div>
        In Short: We do not collect any information.

        <div
          class="pt-2"
          style="font-weight: bold;"
        >
          2. WILL YOUR INFORMATION BE SHARED WITH ANYONE?
        </div>

        In Short: We only share information with your consent,
        to comply with laws, to provide you with services, to protect your rights,
        or to fulfill business obligations.
        We may process or share your data that we hold based on the following legal basis:
        Consent: We may process your data if you have given us specific consent to use
        your personal information for a specific purpose.
        Legitimate Interests: We may process your data when it is reasonably necessary
        to achieve our legitimate business interests.
        Performance of a Contract:
        Where we have entered into a contract with you,
        we may process your personal information to fulfill the terms of our contract.
        Legal Obligations: We may disclose your information where we are legally
        required to do so in order to comply with applicable law, governmental requests,
        a judicial proceeding, court order, or legal process,
        such as in response to a court order or a subpoena
        (including in response to public authorities to meet
        national security or law enforcement requirements).
        Vital Interests: We may disclose your information where we believe
        it is necessary to investigate, prevent, or take action regarding potential
        violations of our policies, suspected fraud,
        situations involving potential threats to the safety of any person and illegal activities,
        or as evidence in litigation in which we are involved.More specifically,
        we may need to process your data or share your personal information
        in the following situations:
        Business Transfers.
        We may share or transfer your information in connection with,
        or during negotiations of, any merger, sale of company assets, financing,
        or acquisition of all or a portion of our business to another company.

        <div
          class="pt-2"
          style="font-weight: bold;"
        >
          3. IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY?
        </div>
        In Short: We may transfer, store,
        and process your information in countries other than your own.
        Our servers are located in.
        If you are accessing our App from outside,
        please be aware that your information may be transferred to,
        stored, and processed by us in our facilities and by those third parties
        with whom we may share your personal information
        (see "WILL YOUR INFORMATION BE SHARED WITH ANYONE?" above),
        in and other countries.
        If you are a resident in the European Economic Area (EEA) or United Kingdom (UK),
        then these countries may not necessarily have data protection
        laws or other similar laws as comprehensive as those in your country.
        We will however take all necessary measures to protect your personal information in accordance
        with this privacy notice and applicable law.

        <div
          class="pt-2"
          style="font-weight: bold;"
        >
          4. HOW LONG DO WE KEEP YOUR INFORMATION?In Short:
        </div>
        We keep your information for as long as necessary to fulfill the purposes outlined in
        this privacy notice unless otherwise required by law.We will only keep your personal
        information for as long as it is necessary for the purposes set out in this privacy notice,
        unless a longer retention period is required or permitted by law
        (such as tax, accounting or other legal requirements).
        No purpose in this notice will require us keeping your
        personal information for longer than __________.
        When we have no ongoing legitimate business need to process your personal information,
        we will either delete or anonymize such information,
        or, if this is not possible
        (for example, because your personal information has been stored in backup archives),
        then we will securely store your personal information and isolate it from any further
        processing until deletion is possible.

        <div
          class="pt-2"
          style="font-weight: bold;"
        >
          5. WHAT ARE YOUR PRIVACY RIGHTS?
        </div>

        In Short:
        You may review, change, or terminate your account at any time.
        If you are a resident in the EEA or UK and you believe we are unlawfully processing
        your personal information, you also have the right to complain to your local data
        protection supervisory authority. You can find their contact details here:
        http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm.
        If you are a resident in Switzerland, the contact details for the data protection
        authorities are available here:
        https://www.edoeb.admin.ch/edoeb/en/home.html.

        <div
          class="pt-2"
          style="font-weight: bold;"
        >
          6. CONTROLS FOR DO-NOT-TRACK FEATURES
        </div>
        Most web browsers and some mobile operating systems and mobile applications
        include a Do-Not-Track ("DNT") feature or setting you can activate to signal
        your privacy preference not to have data about your online browsing activities
        monitored and collected. At this stage no uniform technology standard for recognizing
        and implementing DNT signals has been finalized. As such, we do not currently respond
        to DNT browser signals or any other mechanism that automatically communicates your
        choice not to be tracked online. If a standard for online tracking is adopted that
        we must follow in the future, we will inform you about that practice in a revised
        version of this privacy notice.

        <div
          class="pt-2"
          style="font-weight: bold;"
        >
          7. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
        </div>
        In Short:
        Yes, if you are a resident of California, you are granted specific rights regarding
        access to your personal information.California Civil Code Section 1798.83,
        also known as the "Shine The Light" law, permits our users who are California
        residents to request and obtain from us, once a year and free of charge,
        information about categories of personal information (if any) we disclosed to third
        parties for direct marketing purposes and the names and addresses of all third parties
        with which we shared personal information in the immediately preceding calendar year.
        If you are a California resident and would like to make such a request,
        please submit your request in writing to us using the contact information provided below.
        If you are under 18 years of age, reside in California, and have a registered
        account with the App, you have the right to request removal of unwanted data that you
        publicly post on the App. To request removal of such data,
        please contact us using the contact information provided below,
        and include the email address associated with your account and a statement
        that you reside in California. We will make sure the data is not publicly
        displayed on the App, but please be aware that the data may not be completely
        or comprehensively removed from all our systems (e.g. backups, etc.).

        <div
          class="pt-2"
          style="font-weight: bold;"
        >
          8. DO WE MAKE UPDATES TO THIS NOTICE?
        </div>
        In Short: Yes, we will update this notice as necessary to stay compliant with relevant laws.
        We may update this privacy notice from time to time.
        The updated version will be indicated by an updated "Revised"
        date and the updated version will be effective as soon as it is accessible.
        If we make material changes to this privacy notice, we may notify you
        either by prominently posting a notice of such changes or by directly
        sending you a notification. We encourage you to review this privacy notice
        frequently to be informed of how we are protecting your information.

        <div
          class="pt-2"
          style="font-weight: bold;"
        >
          9. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
        </div>
        If you have questions or comments about this notice,
        you may email us at __________ or by post to:
        Beaker Soft
        20 Heysen Parade
        HAYBOROUGH,
        South Australia 5211
        Australia
      </v-card-text>
    </v-card>
  </div> -->
</template>

<script>

export default {
	data: () => ({
		// company: {
		// 	name: 'Know Your Data',
		// 	email: 'luke@knowyourdata.com.au',
		// },
	}),
};

</script>

<style scoped>



</style>
